import React from "react";
import { Link } from "react-router-dom";
import { ReactTyped } from "react-typed";

const Hero = () => {
  return (
    <div className="text-white">
      <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center">
        <p className="text-[#00df9a] md:text-2xl sm:text-xl font-bold p-2">
          GROW WITH OOH ADVERTISING
        </p>
        <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6">
          Build your Presense.
        </h1>
        <div className="flex justify-center items-center">
          <p className="md:text-4xl sm:text-3xl text-xl font-bold py-3">
            Fast, Flexible, Advertising for
          </p>
          <ReactTyped
            className="md:text-4xl sm:text-3xl text-xl font-bold sm:pl-2 pl-1 text-[#00df9a]"
            strings={[
              "Realtors",
              "Hotels",
              "Restaurants",
              "Events",
              "Apartments",
              "Gyms",
            ]}
            typeSpeed={80}
            backSpeed={30}
            loop
          />
        </div>
        <p className="pt-4 text-gray-500 font-bold md:text-2xl text-xl">
          Browse inventory to find flexible OOH advertising and grow your
          business.
        </p>

        <ul className="flex flex-col md:flex-row px-auto py-3">
          <Link
            to="/home"
            className="bg-[#00df9a] w-[200px] rounded-md text-black font-medium my-6 mx-auto py-3"
          >
            Browse Inventory
          </Link>
          <Link
            to="/contact"
            className="border w-[200px] rounded-md text-white font-medium my-6 mx-auto py-3"
          >
            Contact Sales
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default Hero;
