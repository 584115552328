import React from "react";

const Mission = () => {
  return (
    <div className="flex mx-auto max-w-[1000px] mt-10 px-5">
      <div>
        <h1 className="text-[#00df9a] text-[35px]">Our Mission</h1>
        <p className="text-[20px] text-white mt-3">
          To radically improve the efficiency and effectiveness of out-of-home
          advertising.
        </p>
      </div>
    </div>
  );
};

export default Mission;
