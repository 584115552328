import React from "react";
import { Link } from "react-router-dom"; // Assuming you'll use React Router for navigation

const Footer = () => {
  return (
    <footer className="bg-[#303030] text-white py-8 px-4 mt-10">
      <div className="max-w-[1200px] mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Links Section 1 */}
        <div className="flex flex-col space-y-3">
          <h2 className="text-[#00df9a] font-bold text-xl">Company</h2>
          <Link to="/about" className="hover:text-[#00df9a]">
            About Us
          </Link>
          <Link to="/services" className="hover:text-[#00df9a]">
            Services
          </Link>
          <Link to="/careers" className="hover:text-[#00df9a]">
            Careers
          </Link>
          <Link to="/contact" className="hover:text-[#00df9a]">
            Contact
          </Link>
        </div>

        {/* Links Section 2 */}
        <div className="flex flex-col space-y-3">
          <h2 className="text-[#00df9a] font-bold text-xl">Resources</h2>
          <Link to="/blog" className="hover:text-[#00df9a]">
            Blog
          </Link>
          <Link to="/faq" className="hover:text-[#00df9a]">
            FAQ
          </Link>
          <Link to="/terms" className="hover:text-[#00df9a]">
            Terms of Service
          </Link>
          <Link to="/privacy" className="hover:text-[#00df9a]">
            Privacy Policy
          </Link>
        </div>

        {/* Newsletter Signup or Additional Info */}
        <div className="flex flex-col space-y-3">
          <h2 className="text-[#00df9a] font-bold text-xl">
            Subscribe to our Newsletter
          </h2>
          <form className="flex flex-col space-y-2">
            <input
              type="email"
              placeholder="Your email address"
              className="p-2 rounded-md text-black"
            />
            <button className="bg-[#00df9a] hover:bg-[#00df9adc] text-black rounded-md py-2">
              Subscribe
            </button>
          </form>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className="border-t border-[#7b9990] mt-8 pt-4 text-center">
        <p className="text-[#00df9a] opacity-75">
          © 2024 Capital Sales, LLC. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
