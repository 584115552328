import React from "react";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <div className="flex justify-center items-center h-[600px]">
      <div className="w-[350px] h-[400px] bg-[#303030] border-[#00df9a] border-4 rounded-lg text-white pl-5 pt-3">
        <div className="text-[50px] font-thin">Login</div>
        <h1>Welcome to StreetMark</h1>
        <input
          className="w-[300px] h-12 mt-6 text-[20px] bg-inherit border-2 rounded-lg placeholder:pl-2"
          placeholder="Username"
        ></input>
        <input
          className="w-[300px] h-12 mt-6 text-[20px] bg-inherit border-2 rounded-lg  placeholder:pl-2"
          placeholder="Password"
        ></input>
        <button className="w-[300px] h-12 text-[20px] border-2 rounded-lg border-[#00df9a] text-white text-lg mt-5 hover:bg-[#00df9a] hover:text-black">
          Login
        </button>
        <div className="flex flex-row">
          <h1 className="mt-5 text-slate-500">
            Dont have an account? Get started
          </h1>

          <Link
            to="/contact"
            className="mt-5 text-slate-500 pl-1 underline hover:cursor-pointer"
          >
            Here.
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
