import React from "react";

const Contact = () => {
  return (
    <div>
      <div className="flex flex-col mx-[5%] md:mx-[10%] my-[25px] text-white">
        <div className="text-[35px] md:text-[55px] font-semibold">Contact</div>
        <div className="my-5">
          Contact us about services, features, news, bugs, and more, just fill
          out the form below and we will be in touch.
        </div>
        <div className="flex flex-col *:my-3 *:text-black *:rounded-lg *:pl-2">
          <input
            placeholder="Email"
            className="w-[90%] mx-auto md:mx-0 md:w-[50%] md:min-w-[600px] h-[30px] "
          ></input>
          <input
            placeholder="Subject"
            className="w-[90%] mx-auto md:mx-0 md:w-[50%] md:min-w-[600px] h-[30px]"
          ></input>
          <textarea
            placeholder="Message"
            className="w-[90%] mx-auto md:mx-0 md:w-[50%] md:min-w-[600px] h-[75px]"
          ></textarea>
          <button className="h-[50px] w-[90%] mx-auto md:mx-0 md:w-[250px] bg-[#00df9a] text-black hover:bg-[#53ffa9]">
            Submit
          </button>
          {/* Temorary solution to the footer issue */}
          <div className="h-[30vh]" />
        </div>
      </div>
    </div>
  );
};

export default Contact;
