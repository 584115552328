import React from "react";

const ImageTextBlock = ({ title, text, src, alt, reverse }) => {
  return (
    <div
      className={`flex flex-col ${
        reverse ? "md:flex-row-reverse" : "md:flex-row"
      } justify-center border-2 border-[#00df9862] md:border-none rounded-[10px] m-3 bg-[#414141] md:bg-transparent`}
    >
      <div className="w-[80%] m-[10%] md:w-[35%] md:m-[2.5%]">
        <h1 className=" text-[#00df9a] text-[20px] md:text-[30px]">{title}</h1>
        <p className="text-[12px] md:text-[20px] text-white">{text}</p>
      </div>
      <img
        className="w-[80%] m-[10%] md:w-[35%] md:m-[2.5%]"
        alt={alt}
        src={src}
      />
    </div>
  );
};

export default ImageTextBlock;
