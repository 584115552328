import React from "react";
// import Icon from "@mdi/react";
// import { mdiCheckCircle } from "@mdi/js";
// import { Link } from "react-router-dom";
import PricingContainer from "./PricingContainer";

const Pricing = () => {
  return (
    <div>
      <div className="flex my-[25px] mx-auto 2xl:my-[40px] text-white text-[35px] md:text-[55px] font-normal max-w-[1350px] w-full">
        Pricing Built for <br /> Businesses of all Sizes
      </div>
      {/* <div className="flex flex-col md:flex-row md:w-auto *:mx-auto *:max-w-[580px]">
        <div className="flex flex-col w-[80%] my-6 h-auto md:h-auto bg-[#303030] text-white border-2 border-[#7b9990] hover:border-[#00df9a] transition-colors duration-200 shadow-lg rounded-xl *:p-3 *:px-10">
          <h1 className="flex mx-auto font-bold text-3xl">Join our Map!</h1>
          <div className="flex flex-row">
            <Icon path={mdiCheckCircle} size={1} color={"#00df9a"} />
            <p className="ml-2">
              Get Leads through our network and advertising
            </p>
          </div>
          <div className="flex flex-row">
            <Icon path={mdiCheckCircle} size={1} color={"#00df9a"} />
            <p className="ml-2">Be seen by hundreds of qualified buyers</p>
          </div>
          <div className="flex flex-row">
            <Icon path={mdiCheckCircle} size={1} color={"#00df9a"} />
            <p className="ml-2">Let buyers find you, quickly and efficently</p>
          </div>
          <p className="flex mx-auto text-4xl">
            $25<span className="text-base self-end">/face/month</span>
          </p>
          <Link
            to="/contact"
            className="flex justify-center bg-[#00df9a] hover:bg-[#00df98dc] rounded-lg w-[80%] mx-auto my-5 text-black"
          >
            <h1>Get Started</h1>
          </Link>
        </div>
        <div className="flex flex-col w-[80%] my-6 h-auto md:h-auto bg-[#303030] text-white border-2 border-[#7b9990] hover:border-[#00df9a] transition-colors duration-200 shadow-lg rounded-xl *:p-3 *:px-10">
          <h1 className="flex mx-auto font-bold text-3xl">
            Host Your Website!
          </h1>
          <div className="flex flex-row">
            <Icon path={mdiCheckCircle} size={1} color={"#00df9a"} />
            <p className="ml-2">Get a Streetmark.co/yourcompany domain</p>
          </div>
          <div className="flex flex-row">
            <Icon path={mdiCheckCircle} size={1} color={"#00df9a"} />
            <p className="ml-2">
              Let buyers see all your inventory and locations
            </p>
          </div>
          <div className="flex flex-row">
            <Icon path={mdiCheckCircle} size={1} color={"#00df9a"} />
            <p className="ml-2">Build a state-of-the-art digital presence</p>
          </div>
          <p className="flex mx-auto text-4xl">
            $99<span className="text-base self-end">/month</span>
          </p>
          <Link
            to="/contact"
            className="flex justify-center bg-[#00df9a] hover:bg-[#00df98dc] rounded-lg w-[80%] mx-auto my-5 text-black"
          >
            <h1>Get Started</h1>
          </Link>
        </div>
      </div> */}
      <div className="flex flex-col md:flex-row justify-center">
        <PricingContainer
          title="Basic"
          price="0"
          points={[
            "Join the Streetmark Map",
            "Access Hundreds of Qualified Buyers",
            "Up to 15 Billboards",
            "14.95% Transaction Fee",
          ]}
        />
        <PricingContainer
          title="Premium"
          price="979"
          points={[
            "All Basic Features Plus:",
            "Custom Partner Website",
            "Up to 100 Billboards",
            "Priority Listings",
            "9.95% Transaction Fee",
          ]}
        />
        <PricingContainer
          title="Enterprise"
          price="9,970"
          points={[
            "All Premium Features Plus:",
            "Unlimited Billboards",
            "Realtime Insights and Dashboard",
            "Boosted Search Results",
            "24hr Support",
            "4.95% Transaction Fee",
          ]}
        />
      </div>
    </div>
  );
};

export default Pricing;
