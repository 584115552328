import React from "react";
import Icon from "@mdi/react";
import { mdiCheckCircle } from "@mdi/js";
import { Link } from "react-router-dom";

const PricingContainer = ({ title, price, points = [] }) => {
  return (
    <div className=" w-[90%] mx-auto md:mx-2 md:w-[420px]">
      <div className="flex flex-col my-6 md:h-[625px] lg:h-[525px] bg-[#303030] text-white border-2 border-[#7b9990] hover:border-[#00df9a] transition-colors duration-200 shadow-lg rounded-xl *:p-3 *:px-10">
        <h1 className="flex mx-auto font-normal text-3xl">{title}</h1>

        {/* Map through the array of points */}
        <div className="flex flex-col mt-4">
          {points.map((point, index) => (
            <div className="flex flex-row my-2" key={index}>
              <Icon path={mdiCheckCircle} size={1} color={"#00df9a"} />
              <p className="ml-2">{point}</p>
            </div>
          ))}
        </div>

        <p className="flex flex-grow mx-auto text-4xl items-end">
          {Number(price) === 0 ? (
            <span className="text-4xl">Free</span>
          ) : (
            <>
              ${price}
              <span className="text-base self-end">/month</span>
            </>
          )}
        </p>

        {/* <p className="flex flex-grow mx-auto text-4xl items-end">
          ${price}
          <span className="text-base self-end">/face/month</span>
        </p> */}
        <Link
          to="/contact"
          className="flex justify-center bg-[#00df9a] hover:bg-[#00df98dc] rounded-lg w-[80%] mx-auto my-5 text-black"
        >
          <h1>Get Started</h1>
        </Link>
      </div>
    </div>
  );
};

export default PricingContainer;
