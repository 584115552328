import React from "react";
import Pricing from "../components/Pricing";

const PricingPage = () => {
  return (
    <div>
      <Pricing />
    </div>
  );
};

export default PricingPage;
