import React from "react";
import Map from "../components/Map";
import Mission from "../components/Mission";
import SocialProof from "../components/SocialProof";
import ImageTextBlock from "../components/ImageTextBlock";

const MapPage = () => {
  return (
    <div>
      <Map />
      <SocialProof />
      <ImageTextBlock
        title="Discover Companies Worldwide"
        text="Streetmark offers a global repository of billboards and advertisers. This marketplace allows unprecedented effiency and growth for the outdoor advertising sector. By using Streetmark to buy and sell advertising, you can directly benefit from the best rates, terms, and availiblilty across many providers throughout the world."
        src="mapanimation.gif"
        alt="Map of the World"
      />
      <ImageTextBlock
        title="Track Finances and Boost Revenue"
        text="Streetmark takes care of all of your day to day complexity, so you can focus on growing your business. Easily create and send invoices, recive tax papers, and utalize our management software to keep your boards full."
        src="revenuechart.png"
        alt="Road W/ Billboards"
        reverse={true}
      />
      <ImageTextBlock
        title="Make Sales 24/7 with a Smaller Team"
        text="Take advantage of the Streetmark network. Being on display in front of buyers 24/7 means you can spend less time chasing leads and acomplish day to day tasks with a smaller team, saving you money while improving your booking rates."
        src="revenuegif.gif"
        alt="Road W/ Billboards"
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></div>
      <Mission />
    </div>
  );
};

export default MapPage;
